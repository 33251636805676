import React, { useEffect, FC, useRef, useState } from "react";
import Row from "react-bootstrap/Row";
import "./CoreControllerComponent.css";
import { useFieldArray, UseFormTrigger } from "react-hook-form";
import Controls from "./Controls";
import { Form, Spin } from "antd";
import _ from "lodash";
import {
  ILogic,
  ILogicRequestBody,
  ILogicTypeDataLineApprove,
  ILogicTypeHideControl,
  ILogicTypeReadOnly,
  ILogicTypeReference,
  ILogicTypeReladToLoadData,
  ILogicTypeSourceRelated,
} from "../../../IRequestModel/ILogicModel";
import { ISelectDropdownTemplate } from "../../../IRequestModel/ITemplateDescModel";
import { formatToColumns } from "../../../Helper/formatColumn";
import { formatKeyLogicData } from "../../../Helper/formatKeyLogicData";
import { INumberFormula } from "../../../IRequestModel/INumberFormula";
import { UseFormGetValues } from "react-hook-form";
import { IAutoNumberAttibute } from "../../../IRequestModel/IAutoNumberFormat";
import { GetRvsRunning } from "../../../Services/RequestControlService";
import { onChanceRef, RefResult } from "../../../Helper/RequestRefFunctions";
import { GetApprovalsByMemoIDs } from "../../../Services/LineApprovalsService";
import {
  balanceParentheses,
  calculateAdvanceForm,
  callGenAutoNumber,
  genAutoNum,
  getRefInforValue,
  getValueControl,
} from "../../../Helper/RequestScreenHelper";
import { tableSelectOption } from "../../AntdControlComponent/TableComponent/TableComponent";
import { IRequestOnSubmit } from "../../../IRequestModel/CreateFormControl/IRequestOnSubmit";

import { LogicType } from "../../../constants/logic-type";
import moment from "moment";
import {
  checkIsCorrect,
  processHideControlLogic,
} from "../../../Helper/LogicHelper";
import { useUserContext } from "../../../Context/UserContext";
import { checkTheKeyMatch } from "../../../Helper/arrayHelper";

interface Props {
  templateDesc: any;
  setMemoDetail: any;
  memoDetail: any;
  control: any;
  register: any;
  handleSubmit: any;
  onSubmit: any;
  documentNo: any;
  errors: any;
  reset: any;
  allLogic: ILogic[];
  isFirstRun: any;
  jsonConditions: any;
  setJsonConditions: any;
  onControlChange: (controlName: any, controlValue: any) => any;
  numFormulas: INumberFormula[];
  lineApproval: any;
  setLineApproval: (value: any[]) => void;
  autoNumFormat: IAutoNumberAttibute;
  setValue: any;
  canEditDoc: boolean;
  checkActionPage: string;
  buttonType: string;
  setButtonType: (data: any) => void;
  isControlLoading: boolean;
  setIsControlLoading: (valaue: boolean) => void;
  getLineApproveForAmount: (
    amount: number,
    jsonCondition?: string,
    logicType?: string,
    layout?: any
  ) => Promise<void>;
  previousView: any;
  tableSummaries: any;
  setTableSummaries: (value: any[]) => void;
  refTempSelected: any[];
  listFormNames: any;
  setListRefDocDetails: (value: any[]) => void;
  setListFileAttachDetails: (value: any[]) => void;
  refAttribute: any;
  prepareInitialLogic: (
    logics: ILogic[],
    templateDesc: any,
    _memoDetail: any,
    isOnRef: boolean,
    requestor?: any,
    isRequestorChange?: boolean,
    _lineApproval?: any[],
    isRef?: boolean
  ) => void;
  onProcessLogicReference: (
    logic: ILogic,
    controlTemplate: any,
    controlValue: any,
    control: any
  ) => Promise<{
    loadTo: any[];
    data: any[];
    row: number;
    col: number;
  } | null>;
  previousRefTempSelected: any;
  tableOptions: tableSelectOption[];
  setTableOptions: (value: tableSelectOption[]) => void;
  checkInterFace: boolean;
  onSubmitInterface: (
    formData: any,
    data: IRequestOnSubmit,
    setLoadingInterface: any
  ) => void;
  returnMessage: string;
  listReturn: any;
  interfaceButton: any;
  actionAlert: any;
  edFont?: any;
  defaultFont?: any;
  dfCurr?: any;
  setDfCurr?: (val: any) => void;
  trigger: UseFormTrigger<{
    items: any;
  }>;
  getValues: UseFormGetValues<{
    items: any;
  }>;
}

export const CoreControllerComponentTest: FC<Props> = ({
  setDfCurr,
  dfCurr,
  onProcessLogicReference,
  templateDesc,
  trigger,
  getValues,
  setMemoDetail,
  isFirstRun,
  previousRefTempSelected,
  control,
  register,
  handleSubmit,
  onSubmit,
  onSubmitInterface,
  documentNo,
  errors,
  onControlChange,
  reset,
  memoDetail,
  allLogic,
  jsonConditions,
  setJsonConditions,
  numFormulas,
  lineApproval,
  setLineApproval,
  autoNumFormat,
  setValue,
  canEditDoc,
  checkActionPage,
  buttonType,
  isControlLoading,
  setIsControlLoading,
  getLineApproveForAmount,
  setListFileAttachDetails,
  tableSummaries,
  setTableSummaries,
  previousView,
  refTempSelected,
  listFormNames,
  refAttribute,
  setListRefDocDetails,
  prepareInitialLogic,
  tableOptions,
  setTableOptions,
  checkInterFace,
  returnMessage,
  listReturn,
  interfaceButton,
  actionAlert,
  setButtonType,
  edFont,
  defaultFont,
}) => {
  const { fields, update } = useFieldArray({
    control, // control from template_desc
    name: "items",
  });
  const [keyFrom, setFormKey] = useState<number>(0);
  useEffect(() => {
    setFormKey((prev) => prev + 1);
  }, [fields]);
  const controlRef = useRef([]);
  const [allLogicInTable, setAllLogicInTable] = useState<ILogicRequestBody[]>(
    []
  );
  const [userData] = useUserContext();

  useEffect(() => {
    try {
      if (tableOptions.length > 0) {
        let tableLogics: any[] = [];

        tableOptions.forEach((logic: any) => {
          if (
            logic.logictype === LogicType.DataRelatedToLoadData ||
            logic.logictype === LogicType.DataAjaxLoadTable
          ) {
            const jsonValue: ILogicTypeReladToLoadData =
              logic.jsonvalue &&
              logic.jsonvalue.length > 0 &&
              JSON.parse(logic.jsonvalue.replace(`},\r\n],`, "}],"));

            tableLogics = [
              ...tableLogics,
              ...jsonValue.labelactions.map((labelaction: any) => {
                return {
                  Key: labelaction.label,
                  logictype: logic.logictype,
                  actionlabel: jsonValue.autoloadvaluelabel.label,
                  labelactionfield: jsonValue.autoloadvaluelabel.value,
                  Value: getRefInforValue(
                    labelaction.label,
                    memoDetail,
                    userData
                  ),
                  logicid: logic.logicid,
                };
              }),
            ];
          } else if (logic.logictype === LogicType.DataSourceLoad) {
            const jsonValue: any =
              logic.jsonvalue &&
              logic.jsonvalue.length > 0 &&
              JSON.parse(logic.jsonvalue.replace(`},\r\n],`, "}],"));

            const cons = jsonValue.conditions?.filter((con: any) => {
              return con.objectkey;
            });
            if (cons?.length > 0) {
              cons.map((con: any) => {
                return {
                  logicid: logic.logicid,
                  logictype: logic.logictype,
                  labelactionfield: jsonValue.labelactionfield,
                  Key: con.objectkey,
                  Value: getValueControl(con.objectkey, memoDetail),
                };
              });
            } else {
              tableLogics.push({
                logicid: logic.logicid,
                logictype: logic.logictype,
                labelactionfield: jsonValue.labelactionfield,
                Key: "",
                actionlabel: jsonValue.label,
                Value: null,
              });
            }
          } else if (logic.logictype === LogicType.RefInfomation) {
            const jsonValue: any =
              logic.jsonvalue &&
              logic.jsonvalue.length > 0 &&
              JSON.parse(logic.jsonvalue.replace(`},\r\n],`, "}],"));
            const con = jsonValue.condition.split(" | ");

            tableLogics.push({
              logicid: logic.logicid,
              logictype: logic.logictype,
              Key: con[0],
              actionlabel: con[1],
              Value: getRefInforValue(jsonValue.required, memoDetail, userData),
            });
          } else if (
            logic.logictype === LogicType.HideControl ||
            logic.logictype === LogicType.ReadOnlyControl
          ) {
            const jsonValue: ILogicTypeReadOnly | ILogicTypeHideControl =
              logic.jsonvalue &&
              logic.jsonvalue.length > 0 &&
              JSON.parse(logic.jsonvalue.replace(`},\r\n],`, "}],"));

            const con = jsonValue.conditions;
            const jsonLabel = JSON.parse(jsonValue.label);

            con.forEach((group) => {
              group.conditions.forEach((e) => {
                e.value = e.comparewith
                  ? getRefInforValue(e.comparewith, memoDetail, userData)
                  : e.value;
              });
            });
            tableLogics.push({
              logicid: logic.logicid,
              logictype: logic.logictype,
              Key: jsonLabel.label,
              jsonValue: jsonValue,
            });
          } else if (logic.logictype === LogicType.SetValueControl) {
            const jsonValue: any =
              logic.jsonvalue &&
              logic.jsonvalue.length > 0 &&
              JSON.parse(logic.jsonvalue.replace(`},\r\n],`, "}],"));

            jsonValue.actionControl = JSON.parse(jsonValue.actionControl);
            jsonValue.conditions.forEach((cons: any) => {
              cons.conditions.forEach((con: any) => {
                tableLogics.push({
                  logicid: logic.logicid,
                  logictype: logic.logictype,
                  Key: con.field,
                  operator: con.operator,
                  actionlabel:
                    jsonValue.actionControl.label +
                    "|" +
                    jsonValue.actionControl.isInTable,
                  Value: con.value,
                  valuetoset: cons.valuetoset,
                });
              });
            });
          }
        });

        setAllLogicInTable([...tableLogics]);
      }
    } catch (error) {
      console.error(error);
    }
  }, [tableOptions, memoDetail]);

  const returnToValure = (template: any, _data: any) => {
    let gogo = [];
    for (let j = 0; j < template.attribute.column.length; j++) {
      const column = template.attribute.column[j];
      gogo.push({ value: _data[column.label] });
    }
    return gogo;
  };

  useEffect(() => {
    processRef();
    setIsControlLoading(false);
  }, [refAttribute, refTempSelected]);

  async function processRef() {
    try {
      if (refAttribute) {
        if (refAttribute.refIdOnQuery) {
          try {
            const refResult: RefResult = await onChanceRef(
              refTempSelected,
              memoDetail,
              listFormNames,
              refAttribute,
              fields
            );

            if (refResult.errorMessage) {
            } else if (refResult.items.length > 0) {
              if (canEditDoc) {
                await onRefSelect(refResult, refTempSelected);
              }
            }
          } catch (error) {
            console.error("ref=>error", error);
          }
        } else if (
          !isFirstRun.current &&
          !checkTheKeyMatch(
            previousRefTempSelected?.current,
            refTempSelected,
            "MemoId"
          )
        ) {
          try {
            if (
              memoDetail.memoid !== 0 && refAttribute?.isDefaultLineApprove === null
              // &&
              // refAttribute?.isDefaultLineApprove !== "No" &&
              // previousRefTempSelected?.current
            ) {
                return;
            }
            let refResult: RefResult = await onChanceRef(
              refTempSelected,
              memoDetail,
              listFormNames,
              refAttribute,
              fields
            );

            if (refResult.errorMessage) {
            } else if (refResult.items.length > 0) {
              if (canEditDoc) {
                // calCulateCorecontrol;

                await onRefSelect(refResult, refTempSelected);
              }
            }
          } catch (error) {
            console.error("ref=>error", error);
          }
        }
      }
    } catch (error) {
      console.error("ref=>useEffect=>error", error);
    }
  }

  const isDifferent = (
    refRpreviousRefTempSelectedesult: any,
    refTempSelected: any
  ) => {
    let isDifferent: boolean = false;
    try {
      if (
        refRpreviousRefTempSelectedesult.current &&
        refRpreviousRefTempSelectedesult.current.length > 0
      ) {
        if (refTempSelected && refTempSelected.length > 0) {
          isDifferent =
            refTempSelected[0]?.MemoId !=
              refRpreviousRefTempSelectedesult.current[0]?.MemoId || true;
        }
      } else {
        isDifferent = true;
      }
    } catch (error) {
      console.error("ref=>error", error);
    }
    return isDifferent;
  };

  const onRefSelect = async (refResult: any, refSelected: any) => {
    try {
      setIsControlLoading(true);

      let _listLineApprove: any[] = [...lineApproval];
      let listMemoIds: number[] = [];
      refSelected.forEach((e: any) => {
        listMemoIds.push(e.MemoId);
      });

      if (refAttribute?.isDefaultLineApprove !== "No") {
        const resApprovals: any[] = await GetApprovalsByMemoIDs(listMemoIds);
        if (resApprovals?.length > 0) {
          // setLineApproval([..._listLineApprove, ...resApprovals]);
          setLineApproval([...resApprovals]);
        }
      }

      const _control = await detechRevisionControl(
        memoDetail.template_id,
        refResult.items,
        listMemoIds[0]
      );

      const newControl = await _onControlChangeRef(_control);

      if (newControl.items) {
        setValue("items", [...newControl.items]);
      } else {
        setValue("items", [...newControl]);
      }

      previousRefTempSelected.current = refTempSelected;
      trigger();
      setListRefDocDetails(refResult.listRefDocsDetail);
      if (refResult.listFileAttachDetails)
        setListFileAttachDetails(refResult.listFileAttachDetails);
    } catch (error) {
      console.error("ref=>error", error);
    }
    setIsControlLoading(false);
  };

  const onProcessSummary = async (
    _control: any,
    jsonCondition: any,
    controlTemplate?: any,
    controlValue?: any
  ) => {
    let _amount = 0;
    let isSum = false;
    let isControlHasSum = false;
    try {
      if (
        controlTemplate &&
        controlTemplate.type === "c" &&
        controlTemplate.attribute.summary === "Y"
      ) {
        isControlHasSum = true;
      }

      _control?.forEach((item: any, rowIdx: number) => {
        item.layout.forEach((layout: any) => {
          try {
            if (layout.template.type === "c") {
              if (layout.template.attribute.summary === "Y") {
                isControlHasSum = true;
                isSum = true;
                _amount += Number(layout.data.value || 0);
              }
            } else if (layout.template.type === "tb") {
              const tableCol: any[] = layout.template.attribute.column;
              let tableSum: number = 0;
              if (controlValue?.length > 0) {
                const selTable =
                  controlTemplate?.type === "tb"
                    ? controlValue?.find(
                        (e: any) => e.tableTemp.label === layout.template.label
                      )
                    : tableSummaries?.find(
                        (e: any) => e.tableTemp.label === layout.template.label
                      );

                tableCol.forEach((col: any, colIdx: number) => {
                  if (col.control.template.attribute.summary === "Y") {
                    if (
                      !Number.isNaN(
                        Number(selTable.AllCol[col.control.template.label])
                      )
                    ) {
                      isSum = true;
                      isControlHasSum = true;
                      tableSum += Number(
                        selTable.AllCol[col.control.template.label]
                      );
                    }
                  }
                });
              } else {
                tableCol.forEach((col: any, colIdx: number) => {
                  if (col.control.template.attribute.summary === "Y") {
                    layout.data.row?.forEach((row: any) => {
                      isSum = true;
                      isControlHasSum = true;

                      if (row[colIdx]) {
                        tableSum += Number(row[colIdx]?.value || 0);
                      }
                    });
                  }
                });
              }
              _amount += tableSum;
            }
          } catch (error) {
            console.error("sum=>error", error);
          }
        });
      });

      await getLineApproveForAmount(
        _amount,
        undefined,
        undefined,
        controlTemplate
      );
    } catch (error) {
      console.error("sum=>error", error);
    }
    return {
      sumControl: _control,
      sumRes: {
        _amount: _amount,
        isSum: isSum,
        isControlHasSum: isControlHasSum,
      },
    };
  };

  const onCheckInterface = async (
    buttonType: any,
    setLoadingInterface: any
  ) => {
    memoDetail.template_desc = { items: fields };

    var request: IRequestOnSubmit = {
      buttonType: buttonType,
      inputComment: "",
      waitingFor: "",
      waitingForId: 0,
    };
    let dd: any = await onSubmitInterface(
      memoDetail.template_desc,
      request,
      setLoadingInterface
    );

    if (dd) {
      calculateAdvanceForm(dd);
      setValue("items", [...dd]);
    }
  };

  const calCulateCorecontrol = async (
    currentControl: any,
    controlTemplate?: any,
    controlValue?: any
  ) => {
    let callLogicOnCal: any[] = [];
    try {
      if (controlValue != null) {
        for (let i = 0; i < numFormulas.length; i++) {
          let formula = _.cloneDeep(numFormulas[i]);
          let total: number = NaN;

          if (formula.formula.startsWith("diffdate")) {
            let resulstField = formula.formula.split("=");
            let variables = resulstField[0];
            const selCol = variables
              .substring(variables.indexOf("(") + 1, variables.lastIndexOf(")"))
              .split(",");
            // let match: any = resulstField[0].match(/[^+%\-\*\/=)(]+/g) || [];
            let datepicker1: { [x: string]: moment.Moment } = {
              [selCol[0]]: moment(),
              [selCol[1]]: moment(),
            };

            resulstField.forEach((formu: any) => {
              currentControl.forEach((layout: any) => {
                layout.layout.forEach((col: any) => {
                  if (selCol.includes(col.template.label)) {
                    datepicker1[col.template.label] = moment(col.data.value);
                  }
                });
              });
            });
            total = datepicker1[selCol[0]].diff(datepicker1[selCol[1]], "days");
            if (total >= 1) {
              total += 1;
            }
          } else if (formula.formula.startsWith("difftime")) {
            let resulstField = formula.formula.split("=");
            let variables = resulstField[0];
            const selCol = variables
              .substring(variables.indexOf("(") + 1, variables.lastIndexOf(")"))
              .split(",");
            let datepicker: { [x: string]: moment.Moment } = {
              [selCol[0]]: moment(),
              [selCol[1]]: moment(),
            };
            let res: number = 0;
            let formattedTime: string = "";
            resulstField.forEach((formu: any) => {
              currentControl.forEach((layout: any) => {
                layout.layout.forEach((col: any) => {
                  if (selCol.includes(col.template.label)) {
                    datepicker[col.template.label] = moment(
                      col.data.value,
                      "h.mm"
                    );
                    res = datepicker[selCol[0]].diff(
                      datepicker[selCol[1]],
                      "minutes"
                    );

                    let hours = Math.trunc(res / 60);
                    let remainingMinutes = res % 60;
                    formattedTime =
                      res <= -1 && res >= -9
                        ? `-0.0${Math.abs(remainingMinutes)}`
                        : hours === -0 && res < -10
                        ? `-${hours}.${Math.abs(remainingMinutes)}`
                        : res < -10
                        ? `${hours}.${Math.abs(remainingMinutes)}`
                        : `${hours}.${remainingMinutes}`;
                  }
                });
              });
            });
            total = Number(formattedTime);
          } else {
            let resulstField = formula.formula.split("=");
            let variables = resulstField[0];

            let match: any = variables.match(/[^+%\-\*\/=)(]+/g) || [];

            match = match.map((e: string) => {
              return balanceParentheses(e);
            });

            match.forEach((formu: any) => {
              if (match?.includes("sum") && Array.isArray(controlValue)) {
                if (controlTemplate.type === "tb") {
                  try {
                    const selCol = formu.split(";");

                    if (controlTemplate.label === selCol[0]) {
                      const tableCol: any[] = controlTemplate.attribute.column;

                      const selValue =
                        controlValue[selCol[1]] !== undefined
                          ? controlValue[selCol[1]]
                          : controlValue?.find(
                              (e: any) => e.tableTemp.label === selCol[0]
                            );

                      if (selValue?.AllCol) {
                        tableCol?.forEach((col: any) => {
                          if (selCol[1] === col.label) {
                            total = 0;
                            if (selValue?.AllCol[col.label] != undefined) {
                              total = selValue?.AllCol[col.label];
                            }
                          }
                        });
                      }
                    }
                  } catch (error) {
                    console.error("cal=>error", error);
                  }
                } else {
                  currentControl.forEach((layout: any) => {
                    layout.layout.forEach((col: any) => {
                      if (col.template.type === "tb") {
                        const selCol = formu
                          .substring(
                            formu.indexOf("(") + 1,
                            formu.lastIndexOf(")")
                          )
                          .split(";");
                        const tableCol: any[] = col.template.attribute.column;

                        const selValue = tableSummaries.find(
                          (e: any) => e.tableTemp.label === selCol[0]
                        );

                        tableCol?.forEach((col: any) => {
                          if (selCol[1] === col.label) {
                            if (selValue?.AllCol[col.label]) {
                              variables = variables.replace(
                                formu,
                                selValue.AllCol[selCol[1]].toString()
                              );
                            }
                          }
                        });
                      }
                    });
                  });
                }
              } else {
                currentControl?.forEach((item: any, rowIdx: number) => {
                  item?.layout?.forEach((layout: any) => {
                    if (match.includes(layout.template.label)) {
                      if (layout.data.value && layout.data.value !== "") {
                        variables = variables.replace(
                          layout.template.label,
                          layout.data.value.toString().replaceAll(",", "")
                        );
                      } else {
                        variables = variables.replace(
                          layout.template.label,
                          "0"
                        );
                      }
                    }
                  });
                });
              }
            });
            var regExp = /[a-zA-Zก-๏]/g;

            if (!regExp.test(variables)) {
              total = eval(variables);
            }
          }

          if (!isNaN(total)) {
            const callLogics: any[] = await checkCallLogic(
              allLogic,
              currentControl[formula.rowIndex].layout[formula.colIndex].template
            );

            if (
              currentControl[formula.rowIndex].layout[formula.colIndex].template
                .type === "d"
            ) {
              const dateTemplate =
                currentControl[formula.rowIndex].layout[formula.colIndex]
                  .template;

              switch (dateTemplate.attribute.formula.method) {
                case "Add":
                  currentControl[formula.rowIndex].layout[
                    formula.colIndex
                  ].data.value = moment()
                    .add(total, "days")
                    .format("DD MMM yyyy");
                  break;
                case "Subtract":
                  currentControl[formula.rowIndex].layout[
                    formula.colIndex
                  ].data.value = moment()
                    .subtract(total, "days")
                    .format("DD MMM yyyy");
                  break;
              }
            } else {
              currentControl[formula.rowIndex].layout[
                formula.colIndex
              ].data.value = total.toFixed(
                currentControl[formula.rowIndex].layout[formula.colIndex]
                  .template.attribute.decimal
              );
            }

            if (callLogics.length > 0) {
              callLogicOnCal = callLogics;
            }
          }
        }
      }
    } catch (error) {
      console.error("cal=>error", error);
    }

    return currentControl;
  };

  const updateTableSummaries = (tableTemp: any, value: any) => {
    if (tableSummaries) {
      const _tableSummaries = JSON.stringify(tableSummaries);
      let _sumTable = JSON.parse(_tableSummaries);

      _sumTable.map((e: any) => {
        if (e.tableTemp.label === tableTemp.label) {
          e.AllCol = value;
        }
      });

      if (!_.isEqual(_sumTable, tableSummaries)) {
        _onControlChange(tableTemp, _sumTable, true);
        setTableSummaries([..._sumTable]);
      }
    }
  };

  //check if control has logic
  const checkCallLogic = (logics: any, controlTemplate: any) => {
    let callLogic: any[] = [];

    logics.forEach((logic: ILogic) => {
      if (logic.logictype === LogicType.DataSourceRelated) {
        const jsonValue: ILogicTypeSourceRelated =
          logic.jsonvalue &&
          logic.jsonvalue.length > 0 &&
          JSON.parse(logic.jsonvalue.replace(`},\r\n],`, "}],"));

        if (controlTemplate.type === "tb") {
          for (let j = 0; j < controlTemplate.attribute.column.length; j++) {
            const col = controlTemplate.attribute.column[j];
            if (jsonValue.label === col.label) {
              callLogic.push(logic);
            }
          }
        } else {
          if (jsonValue.label === controlTemplate.label) {
            callLogic.push(logic);
          }
        }
      } else if (logic.logictype === LogicType.DataAjaxLoadTable) {
        const jsonValue: ILogicTypeSourceRelated =
          logic.jsonvalue &&
          logic.jsonvalue.length > 0 &&
          JSON.parse(logic.jsonvalue.replace(`},\r\n],`, "}],"));

        const selectAction = jsonValue.labelactions.find(
          (e) => e.label === controlTemplate.label
        );
        if (selectAction) {
          callLogic.push(logic);
        }
      } else if (logic.logictype === LogicType.DataRelatedToLoadData) {
        const jsonValue: ILogicTypeReladToLoadData =
          logic.jsonvalue &&
          logic.jsonvalue.length > 0 &&
          JSON.parse(logic.jsonvalue.replace(`},\r\n],`, "}],"));
        if (controlTemplate.type === "tb") {
          for (let i = 0; i < jsonValue.labelactions.length; i++) {
            const labelAction = jsonValue.labelactions[i];
            for (let j = 0; j < controlTemplate.attribute.column.length; j++) {
              const col = controlTemplate.attribute.column[j];
              if (labelAction.label === col.label) {
                callLogic.push(logic);
              }
            }
          }
        } else {
          for (let i = 0; i < jsonValue.labelactions.length; i++) {
            const labelAction = jsonValue.labelactions[i];

            if (labelAction.label === controlTemplate.label) {
              callLogic.push(logic);
            }
          }
        }
      } else if (logic.logictype === LogicType.Reference) {
        const jsonValue: ILogicTypeReference =
          logic.jsonvalue &&
          logic.jsonvalue.length > 0 &&
          JSON.parse(logic.jsonvalue.replace(`},\r\n],`, "}],"));
        for (let i = 0; i < jsonValue.Filter.length; i++) {
          const filter = jsonValue.Filter[i];
          if (filter.TBColumn === controlTemplate.label) {
            callLogic.push(logic);
          }
        }
      } else if (logic.logictype === LogicType.DataLineApprove) {
        const jsonValue: ILogicTypeDataLineApprove =
          logic.jsonvalue &&
          logic.jsonvalue.length > 0 &&
          JSON.parse(logic.jsonvalue.replace(`},\r\n],`, "}],"));
        if (jsonValue.label === controlTemplate.label) {
          callLogic.push(logic);
        }
      } else if (
        logic.logictype === LogicType.SetValueControl ||
        logic.logictype === LogicType.HideControl ||
        logic.logictype === LogicType.ReadOnlyControl
      ) {
        const jsonValue: any = JSON.parse(logic.jsonvalue);
        let allLabel: any[] = [];
        const tempColumns: string[] =
          controlTemplate?.attribute?.column?.map(
            (e: { label: any }) => e.label
          ) ?? [];

        jsonValue.conditions.map((con: any) => {
          try {
            if (typeof con.field === "string") {
              con.field = JSON.parse(con.field);
            }

            con.conditions.forEach((condition: any) => {
              allLabel.push(condition.field);
            });
          } catch (error) {
            console.error(error);
          }
        });

        if (
          allLabel.includes(controlTemplate.label) ||
          allLabel.filter((e) => tempColumns.includes(e)).length > 0
        ) {
          callLogic.push(logic);
        }
      }
    });

    return callLogic;
  };
  const _onControlChangeRef = async (refControl: any) => {
    let _jsonConditions = jsonConditions;
    let template: any = null;
    if (!isFirstRun?.current) {
      if (previousView?.current === "2") {
        const logics = allLogic;

        let oldRefControl = refControl;

        oldRefControl = await prepareInitialLogic(
          logics,
          {
            items: oldRefControl,
          },
          memoDetail,
          memoDetail.status,
          memoDetail.requestor,
          undefined,
          undefined,
          true
        );

        for (let i = 0; i < oldRefControl.items.length; i++) {
          const item = oldRefControl.items[i];
          for (let j = 0; j < item.layout.length; j++) {
            const layout = item.layout[j];
            if (layout.template.type !== "tb") {
              const callLogic = checkCallLogic(
                logics.filter((e) => e.logictype === LogicType.DataLineApprove),
                layout.template
              );

              if (callLogic.length > 0) {
                template = layout.template;
              }
              let table_dd_to_dd_Result: any[] = [];

              for (let i = 0; i < callLogic.length; i++) {
                const _response = await onProcessLogic(
                  callLogic[i],
                  layout.template,
                  layout.data.value,
                  refControl,
                  false
                );

                if (_response?.type == "lineapprove") {
                  _jsonConditions = _response?.value || "";
                } else {
                  table_dd_to_dd_Result.push(callLogic[i]);
                }
              }
              const dd = Array.from(
                new Set([...tableOptions, ...table_dd_to_dd_Result])
              );

              setTableOptions([...dd]);
            }
          }
        }
        await calculateAdvanceForm(oldRefControl.items);

        if (autoNumFormat.formats.length > 0 && canEditDoc) {
          oldRefControl.items = await genAutoNum(
            oldRefControl.items,
            autoNumFormat,
            memoDetail.template_id,
            memoDetail.status
          );
        }

        const { sumControl } = await onProcessSummary(
          oldRefControl.items,
          _jsonConditions,
          template,
          null
        );
        refControl = sumControl;
        if (_jsonConditions) {
          if (_jsonConditions.type && _jsonConditions.value) {
            oldRefControl = _jsonConditions.value;
          }
        }
        return oldRefControl;
      }
      return refControl;
    }
    return refControl;
  };

  //trigger when control value change
  const _onControlChange = async (
    controlTemplate: ISelectDropdownTemplate | any, //controled change template
    controlValue: any, //value
    isInTable: boolean = false, // if control in table
    isRef?: boolean
  ) => {
    try {
      let _amount = 0;
      let _jsonConditions: string = jsonConditions; //condition for datalineapprove logic

      setIsControlLoading(true);

      /**
       * Condition check controlValue must not null or undefined.
       * But allow controlValue is zero (0) for input type number and input zero value.
       * And not allow trigger when change tap to Information screen
       */
      if (controlValue != null && !isFirstRun?.current) {
        if (previousView?.current === "2") {
          const logics = allLogic; //all template logic

          //check if triggered control has logic or not
          const callLogic = checkCallLogic(logics, controlTemplate);

          //unrelate constant
          const _controlString = JSON.stringify(fields);
          const _control = JSON.parse(_controlString);

          const newControlString = JSON.stringify(_control);
          let newControl = JSON.parse(newControlString);

          let table_dd_to_dd_Result: any[] = [];
          //check if can call logic and this control not type = tb
          let resetForm = false;
          //calculate all control value to amount in memodetail
          newControl = await calCulateCorecontrol(
            _control,
            controlTemplate,
            controlValue
          );

          if (dfCurr && dfCurr?.Value2 === controlTemplate.label) {
            if (setDfCurr)
              setDfCurr((prevState: any) => ({
                ...prevState,
                Value1: controlValue,
              }));
          }

          const { sumControl, sumRes } = await onProcessSummary(
            newControl,
            _jsonConditions,
            controlTemplate,
            controlValue
          );

          newControl = sumControl;

          if (
            (controlTemplate.type !== "tb" || isInTable) &&
            callLogic.length > 0
          ) {
            callLogic.sort((a, b) => {
              if (
                a.logictype === "datalineapprove" &&
                b.logictype !== "datalineapprove"
              ) {
                return 1;
              } else if (
                a.logictype !== "datalineapprove" &&
                b.logictype === "datalineapprove"
              ) {
                return -1;
              } else {
                return 0;
              }
            });
            if (isInTable) {
              callLogic.sort((a, b) => {
                if (
                  a.logictype === "datasourcerelated" &&
                  b.logictype !== "datasourcerelated"
                ) {
                  return -1;
                } else if (
                  a.logictype !== "datasourcerelated" &&
                  b.logictype === "datasourcerelated"
                ) {
                  return 1;
                } else {
                  return 0;
                }
              });
            }

            if (isInTable) {
              callLogic.sort((a, b) => {
                if (
                  a.logictype === "datasourcerelated" &&
                  b.logictype !== "datasourcerelated"
                ) {
                  return -1;
                } else if (
                  a.logictype !== "datasourcerelated" &&
                  b.logictype === "datasourcerelated"
                ) {
                  return 1;
                } else {
                  return 0;
                }
              });
            }

            for (let i = 0; i < callLogic.length; i++) {
              const { type, value, controls } = await onProcessLogic(
                callLogic[i],
                controlTemplate,
                controlValue,
                newControl,
                isInTable
              );

              if (type === "table_dd_to_dd") {
                //setNewOptionToControl
                table_dd_to_dd_Result.push(callLogic[i]);
              } else if (type === "control") {
                newControl = value;
              }
              //return value to save function in TableComponent
              if ((type === "table" || type === "control") && isInTable) {
                setIsControlLoading(false);
                if (type === "table" && isInTable) {
                  table_dd_to_dd_Result.push(callLogic[i]);
                } else {
                  setValue("items", [...value]);
                  trigger();
                }

                return { type, value };
              } else if (type === "lineapprove" && !isInTable) {
                //set jsonconditions

                _jsonConditions = value || "";
              }
            }
          }

          if (
            autoNumFormat.formats.length > 0 &&
            canEditDoc &&
            callGenAutoNumber(autoNumFormat, controlTemplate)
          ) {
            newControl = await genAutoNum(
              newControl,
              autoNumFormat,
              memoDetail.template_id,
              memoDetail.status
            );
          }

          const dd = Array.from(
            new Set([...tableOptions, ...table_dd_to_dd_Result])
          );

          setTableOptions([...dd]);

          if (newControl && !isRef) {
            if (!_.isEqual(getValues(), { items: newControl })) {
              setValue("items", [...newControl]);
              trigger();
            }
          }
        }
      }
      setIsControlLoading(false);
    } catch (error) {
      setIsControlLoading(false);
      console.error("core=>error", error);
    }
  };

  const detechRevisionControl = async (
    _templateId: any,
    _control: any,
    memoId?: number
  ) => {
    try {
      if (!["New Request"].includes(memoDetail.status)) return _control;
      let requestBody: any = {};
      let items: any[] = [];
      let rowIndex = -1;
      let colIndex = -1;

      _control.forEach((item: any, rowIdx: number) => {
        item.layout.forEach((layout: any, colIdx: number) => {
          if (layout.template.type === "rvs") {
            requestBody.TemplateId = _templateId;
            requestBody.RefId = memoId;
            requestBody.Digit = layout.template.attribute.digit;
            requestBody.Labelrevision = layout.template.label;
            requestBody.Alter = layout.template.alter;
            requestBody.MemoId = null;
            rowIndex = rowIdx;
            colIndex = colIdx;
          }
        });
      });

      if (rowIndex !== -1 && colIndex !== -1) {
        if (
          _control[rowIndex].layout[colIndex].template.attribute.conditions
            .length > 0
        ) {
          const rvsCon = _control[rowIndex].layout[colIndex];
          rvsCon.template.attribute.conditions.map((con: any) => {
            _control.map((item: any, rowIdx: number) => {
              item.layout.map((layout: any, colIdx: number) => {
                if (con.label === layout.template.label) {
                  items.push({
                    Label: con.label,
                    value: layout.data.value,
                  });
                }
              });
            });
          });
        }

        requestBody.Itemlabel = items;

        if (requestBody.Itemlabel.length > 0) {
          let isCheck = true;

          requestBody.Itemlabel.forEach((item: any) => {
            if (!item.value || item.value === "") {
              isCheck = false;
              return;
            }
          });

          if (isCheck) {
            const revision = await GetRvsRunning(requestBody);
            _control[rowIndex].layout[colIndex].data.value = revision.item;
          }
        }
      }
      return _control;
    } catch (error) {
      console.error("rvs=>error", error);
    }
  };

  //process datasourcerelate function
  const onProcessLogicDataSourceRelated = async (
    logic: ILogic,
    controlTemplate: any,
    controlValue: any,
    isInTable: boolean,
    control: any
  ) => {
    let responseDataSourceLoadRelated: any = {
      relateGroup: [],
      data: [],
    };

    const controlDescription =
      controlTemplate?.description ||
      controlTemplate?.attribute?.description ||
      "";
    const jsonValue: ILogicTypeSourceRelated =
      logic.jsonvalue &&
      logic.jsonvalue.length > 0 &&
      JSON.parse(logic.jsonvalue.replace(`},\r\n],`, "}],"));
    let jsonRelated: any = [];

    if (jsonValue.label === controlTemplate.label && !isInTable) {
      //body to fetch data
      const conditions = jsonValue.conditions;

      if (conditions.length > 0) {
        conditions.forEach((e) => {
          if (!e.value) {
            for (let k = 0; k < control.length; k++) {
              const item = control[k];
              for (let l = 0; l < item.layout.length; l++) {
                const _layout = item.layout[l];
                const _label = _layout.label || _layout.template.label;
                const _des =
                  _layout?.template?.description ||
                  _layout?.template?.attribute?.description ||
                  "";
                if (e.label === _label && controlDescription == _des) {
                  jsonRelated.push({
                    Key: _label,
                    Value: _layout.data.value,
                    logicid: logic.logicid,
                  });
                }
              }
            }
          }
        });
        responseDataSourceLoadRelated.data = await onDatareladToLoadData(
          jsonRelated
        );
      } else {
        const dataJson = {
          Key: controlTemplate.label,
          Value: controlValue,
          logicid: logic.logicid,
        };

        responseDataSourceLoadRelated.data = await onDataSourceLoadRelated(
          dataJson
        );
      }
      if (responseDataSourceLoadRelated.data?.length > 0) {
        //find control position to put data in
        for (let i = 0; i < jsonValue.relatedvalue?.length; i++) {
          const relateValue = jsonValue?.relatedvalue[i];
          for (let j = 0; j < control?.length; j++) {
            const item = control[j];
            for (let k = 0; k < item.layout?.length; k++) {
              const layout = item?.layout[k];
              if (
                JSON.stringify(layout.template) != "{}" &&
                !["l", "em"].includes(layout.template.type) &&
                layout.template.label === relateValue.label &&
                controlDescription ===
                  (layout.template?.description ||
                    layout.template?.attribute?.description ||
                    "")
                // layout.template.label === relateValue.label
              ) {
                responseDataSourceLoadRelated.relateGroup.push({
                  row: j,
                  col: k,
                  relateValue,
                });
              }
            }
          }
        }

        return { responseDataSourceLoadRelated, isInTable };
      }
    } else if (isInTable) {
      if (controlValue.dataIndex === jsonValue.label) {
        const _dataJson: any[] = [];
        const conditions = jsonValue.conditions;
        const _control = controlTemplate.attribute.column;

        conditions.forEach((e) => {
          for (let k = 0; k < _control.length; k++) {
            const item = _control[k];
            if (e.label === item.label) {
              _dataJson.push({
                Key: item.label,
                Value: controlValue[e.label],
                logicid: logic.logicid,
              });
            }
          }
        });

        responseDataSourceLoadRelated.data = await onDatareladToLoadData(
          _dataJson
        );

        if (responseDataSourceLoadRelated.data.length > 0) {
          for (let i = 0; i < jsonValue.relatedvalue.length; i++) {
            const relateValue = jsonValue.relatedvalue[i];
            for (let j = 0; j < controlTemplate.attribute.column.length; j++) {
              const item = controlTemplate.attribute.column[j];
              for (
                let k = 0;
                k < responseDataSourceLoadRelated.data.length;
                k++
              ) {
                const relate = responseDataSourceLoadRelated.data[k];
                for (const [keyRelate, valueRelate] of Object.entries(relate)) {
                  if (
                    keyRelate === relateValue.value &&
                    relateValue.label === item.label
                  ) {
                    responseDataSourceLoadRelated.relateGroup.push({
                      row: controlValue.key,
                      col: j,
                      valueRelate,
                    });
                  }
                }
              }
            }
          }
          return { responseDataSourceLoadRelated, isInTable };
        }
      }
    }
    return false;
  };

  const onProcessLogicAjax = async (
    logic: ILogic,
    controlTemplate: any,
    controlValue: any,
    isInTable: boolean
  ) => {
    let responseDataSourceLoadRelated: any = {
      relateGroup: [],
      data: [],
      actionFrom: { row: -1, col: -1 },
    };
    const jsonValue: ILogicTypeSourceRelated =
      logic.jsonvalue &&
      logic.jsonvalue.length > 0 &&
      JSON.parse(logic.jsonvalue.replace(`},\r\n],`, "}],"));

    //body to fetch data
    const dataJson = {
      Key: controlTemplate.label,
      Value: controlValue,
      logicid: logic.logicid,
    };
    responseDataSourceLoadRelated.data = await onDataSourceLoadRelated(
      dataJson
    );
    //check if has response data
    if (responseDataSourceLoadRelated.data.length > 0) {
      //find control position to put data in
      const relateValue = jsonValue.autoloadvaluelabel.label;
      for (let i = 0; i < jsonValue.labelactions.length; i++) {
        if (jsonValue.labelactions[i].label === controlTemplate.label) {
          for (let j = 0; j < templateDesc?.items?.length; j++) {
            const item = templateDesc?.items[j];
            for (let k = 0; k < item.layout?.length; k++) {
              const layout = item?.layout[k];
              if (layout.template.type === "tb") {
                isInTable = true;
                layout.template.attribute.column.forEach(
                  (col: any, colIdx: number) => {
                    if (col.label === relateValue) {
                      responseDataSourceLoadRelated.relateGroup.push({
                        row: j,
                        col: k,
                        relateValue,
                        tableCol: colIdx,
                      });
                    }
                  }
                );
              }
            }
          }
        }
      }

      return { responseDataSourceLoadRelated, isInTable };
    }

    return { responseDataSourceLoadRelated, isInTable };
  };

  //process datalineapprove logic
  const onProcessLogicLineApprove = async (
    logic: ILogic,
    controlTemplate: any,
    controlValue: any,
    control: any
  ) => {
    const jsonValue: ILogicTypeDataLineApprove =
      logic.jsonvalue &&
      logic.jsonvalue.length > 0 &&
      JSON.parse(logic.jsonvalue.replace(`},\r\n],`, "}],"));

    const conditionsValue = onFilterCondotionsValue(
      jsonValue,
      control,
      controlTemplate,
      controlValue
    );

    if (conditionsValue.length > 0) {
      return JSON.stringify({
        logicid: logic.logicid,
        conditions: conditionsValue,
      });
    }
    return false;
  };
  const onProcessLogicDataRelatedToLoadData = async (
    logic: ILogic,
    controlTemplate: any,
    controlValue: any,
    control: any,
    isInTable: boolean
  ) => {
    const jsonValue: ILogicTypeReladToLoadData =
      logic.jsonvalue &&
      logic.jsonvalue.length > 0 &&
      JSON.parse(logic.jsonvalue.replace(`},\r\n],`, "}],"));
    const { dataJson, loadTo, actionFrom } = onFindDataJson(
      controlTemplate,
      controlValue,
      control,
      jsonValue,
      logic.logicid,
      isInTable
    );
    console.log(dataJson);

    if (dataJson) {
      // check if has response data
      if (dataJson.length > 0) {
        if (isFirstRun.current || checkIfLogicsHaveValue(dataJson)) {
          const rawData: any = await onDatareladToLoadData(dataJson);

          if (rawData && loadTo) {
            let data = formatKeyLogicData({ data: rawData });

            return { data, loadTo, actionFrom };
          }
        }
      }
    }
    return false;
  };

  const checkIfLogicsHaveValue = (dataJson: any[]) => {
    let _bool = false;
    dataJson.forEach((data) => {
      Object.keys(data).map((key) => {
        if (key.toLowerCase() === "value") {
          _bool = true;
        }
      });
    });

    return _bool;
  };

  const checkIfGetData = (data: any[]) => {
    let _bool = true;

    for (let i = 0; i < data.length; i++) {
      if (data[i].item === "") {
        _bool = false;
        return _bool;
      }
    }
    if (data.length === 1 && data[0].item === "-- Please Select --") {
      _bool = false;
      return _bool;
    }
    return _bool;
  };

  const onProcessLogic = async (
    logic: ILogic,
    controlTemplate: ISelectDropdownTemplate | any,
    controlValue: any,
    _control: any,
    isInTable: boolean,
    sumRes?: any
  ): Promise<{
    type:
      | "control"
      | "lineapprove"
      | "table"
      | "table_dd_to_dd"
      | "controlValue"
      | null;
    value: any;
    controls?: any;
  }> => {
    let responseDataSourceLoadRelated: any = {
      relateGroup: [],
      data: [],
    };
    const controlString = _control
      ? JSON.stringify(_control)
      : JSON.stringify(templateDesc.items);

    let newControl;
    try {
      if (controlString) {
        newControl = JSON.parse(controlString);
      }
      if (logic) {
        //find logic that control have

        if (logic.logictype === LogicType.DataSourceRelated) {
          const responseData = await onProcessLogicDataSourceRelated(
            logic,
            controlTemplate,
            controlValue,
            isInTable,
            newControl
          );

          if (responseData) {
            //check if this control in table
            if (controlTemplate.type === "tb" && isInTable) {
              //format value to data in template type = table
              const ee = returnToValure(controlTemplate, controlValue);

              let newColumn: any = {};

              for (
                let i = 0;
                i <
                responseData.responseDataSourceLoadRelated.relateGroup.length;
                i++
              ) {
                //put data to specific row
                const relateGroup =
                  responseData.responseDataSourceLoadRelated.relateGroup[i];
                newControl[controlValue.rowTemplate].layout[
                  controlValue.colTemplate
                ].data.row[relateGroup.row] = ee;

                newControl[controlValue.rowTemplate].layout[
                  controlValue.colTemplate
                ].data.row[relateGroup.row][relateGroup.col].value =
                  relateGroup.valueRelate;

                //format value to row value
                newColumn = formatToColumns(
                  controlTemplate.attribute.column,
                  newControl[controlValue.rowTemplate].layout[
                    controlValue.colTemplate
                  ].data.row[relateGroup.row]
                );
              }

              const responseDataTable = {
                ...newColumn,
                key: controlValue.key,
              };

              return { type: "table", value: responseDataTable };
            } else {
              for (
                let i = 0;
                i <
                responseData.responseDataSourceLoadRelated.relateGroup.length;
                i++
              ) {
                const relateGroup =
                  responseData.responseDataSourceLoadRelated.relateGroup[i];

                for (
                  let j = 0;
                  j < responseData.responseDataSourceLoadRelated.data.length;
                  j++
                ) {
                  const relateData =
                    responseData.responseDataSourceLoadRelated.data[j];

                  if (
                    newControl[relateGroup.row].layout[relateGroup.col].template
                      .type === "cb"
                  ) {
                    let items =
                      newControl[relateGroup.row].layout[relateGroup.col]
                        .template.attribute.items;
                    const resVals: string[] =
                      relateData[relateGroup.relateValue.value].split(",");

                    items = items.map((e: any) => {
                      if (resVals.includes(e.item)) {
                        return "Y";
                      }
                      return "N";
                    });
                    newControl[relateGroup.row].layout[
                      relateGroup.col
                    ].data.item = items;
                    newControl[relateGroup.row].layout[
                      relateGroup.col
                    ].data.value = relateData[relateGroup.relateValue.value];
                  } else {
                    newControl[relateGroup.row].layout[
                      relateGroup.col
                    ].data.value = relateData[relateGroup.relateValue.value];
                  }
                  if (
                    callGenAutoNumber(
                      autoNumFormat,
                      newControl[relateGroup.row].layout[relateGroup.col]
                        .template
                    )
                  ) {
                    newControl = await genAutoNum(
                      newControl,
                      autoNumFormat,
                      memoDetail.template_id,
                      memoDetail.status
                    );
                  }
                }
              }
              return { type: "control", value: newControl };
            }
          }
        } else if (logic.logictype === LogicType.Reference) {
          const responseData = await onProcessLogicReference(
            logic,
            controlTemplate,
            controlValue,
            newControl
          );

          if (responseData) {
            //map data from api response
            const permittedValues = responseData.data.map(function (
              value: any
            ) {
              let res: any = {};
              for (let i = 0; i < responseData.loadTo.length; i++) {
                const field = responseData.loadTo[i];
                res[field["TBColumn"]] = value[field["MSTColumn"]];
              }
              return res;
            });
            let resRow: any[] = [];

            if (permittedValues) {
              for (let i = 0; i < permittedValues.length; i++) {
                const perValue = permittedValues[i];

                //create empty table data as response api length
                let colData = new Array(
                  newControl[responseData.row].layout[
                    responseData.col
                  ].template.attribute.column.length
                ).fill({ value: "" });

                //loop for put data to table
                for (const [key, value] of Object.entries(perValue)) {
                  //loop throught table template column length
                  for (
                    let j = 0;
                    j <
                    newControl[responseData.row].layout[responseData.col]
                      .template.attribute.column.length;
                    j++
                  ) {
                    const _tableCol =
                      newControl[responseData.row].layout[responseData.col]
                        .template.attribute.column[j];

                    // if column label = key of data response, put data in
                    if (_tableCol.label === key) {
                      colData[j] = { value };
                    }
                  }
                }

                resRow.push(colData);
              }

              newControl[responseData.row].layout[responseData.col].data.row =
                resRow;
              return { type: "control", value: newControl };
            }
          }
        } else if (logic.logictype === LogicType.DataLineApprove) {
          const responseData = await onProcessLogicLineApprove(
            logic,
            controlTemplate,
            controlValue,
            newControl
          );

          if (responseData) {
            await getLineApproveForAmount(
              sumRes?._amount,
              responseData,
              LogicType.DataLineApprove,
              controlTemplate
            );
            setIsControlLoading(false);

            // return { type: "lineapprove", value: responseData };
          }
        } else if (logic.logictype === LogicType.DataRelatedToLoadData) {
          const jsonValue: ILogicTypeReladToLoadData =
            logic.jsonvalue &&
            logic.jsonvalue.length > 0 &&
            JSON.parse(logic.jsonvalue.replace(`},\r\n],`, "}],"));
          const isInTable = jsonValue.labelactions
            .map((e) => e.labelintablestatus)
            .includes("true");
          if (isInTable) {
            return {
              type: "table_dd_to_dd",
              value: null,
            };
          } else {
            for (let k = 0; k < newControl.length; k++) {
              for (let l = 0; l < newControl[k].layout.length; l++) {
                const _layout = newControl[k].layout[l];
                const _label = _layout.label || _layout.template.label;
                if (jsonValue.autoloadvaluelabel.label === _label) {
                  newControl[k].layout[l].template.attribute.items = [];
                  newControl[k].layout[l].data.value = "-- Please Select --";
                }
              }
            }
            return { type: "control", value: newControl };
          }
        } else if (logic.logictype === LogicType.DataAjaxLoadTable) {
          const responseData = await onProcessLogicAjax(
            logic,
            controlTemplate,
            controlValue,
            isInTable
          );

          if (responseData.isInTable) {
            return {
              type: "table_dd_to_dd",
              value: null,
            };
          } else if (responseData) {
            for (
              let i = 0;
              i < responseData.responseDataSourceLoadRelated.relateGroup.length;
              i++
            ) {
              const processData = formatKeyLogicData(
                responseData.responseDataSourceLoadRelated
              );

              newControl[processData.relateGroup[i].row].layout[
                processData.relateGroup[i].col
              ].template.attribute.column[
                processData.relateGroup[i].tableCol
              ].control.template.attribute.items = processData.data;
            }
            reset({
              items: _control,
            });
          }
        } else if (logic.logictype === LogicType.HideControl) {
          const jsonValue: ILogicTypeHideControl = JSON.parse(logic.jsonvalue);

          processHideControlLogic(
            jsonValue,
            newControl,
            "isShow",
            memoDetail,
            userData,
            controlValue,
            controlTemplate
          );
          return {
            type: "control",
            value: newControl,
          };
        } else if (logic.logictype === LogicType.ReadOnlyControl) {
          const jsonValue: ILogicTypeReadOnly = JSON.parse(logic.jsonvalue);
          processHideControlLogic(
            jsonValue,
            newControl,
            "isReadOnly",
            memoDetail,
            userData,
            controlValue,
            controlTemplate
          );

          return {
            type: "control",
            value: newControl,
          };
        } else if (logic.logictype === LogicType.SetValueControl) {
          const jsonValue: any =
            logic.jsonvalue &&
            logic.jsonvalue.length > 0 &&
            JSON.parse(logic.jsonvalue.replace(`},\r\n],`, "}],"));

          let valueToSet: any = null;
          jsonValue.conditions.forEach(
            (consGroup: { conditions: string | any[]; valuetoset: any }) => {
              let isConditionsSatisfied = true;

              for (let i = 0; i < consGroup.conditions.length; i++) {
                const con = consGroup.conditions[i];
                let conditionSatisfied = false;

                for (let k = 0; k < _control.length; k++) {
                  for (let l = 0; l < _control[k].layout.length; l++) {
                    const _layout = _control[k].layout[l];
                    const _label = _layout.label || _layout.template.label;

                    if (con.field === _label) {
                      const dataValue = _layout.data.value;
                      conditionSatisfied = checkIsCorrect(
                        dataValue,
                        con.operator,
                        con.value
                      );
                      if (i === 0) {
                        isConditionsSatisfied = conditionSatisfied;
                      } else {
                        isConditionsSatisfied =
                          isConditionsSatisfied && conditionSatisfied;
                      }
                    }
                  }
                }
              }

              if (isConditionsSatisfied) {
                valueToSet = consGroup.valuetoset;
              }
            }
          );

          jsonValue.actionControl = JSON.parse(jsonValue.actionControl);
          if (
            controlTemplate.type === "tb" &&
            jsonValue.actionControl.isInTable
          ) {
            if (controlValue[jsonValue.actionControl.label] !== undefined) {
              controlValue[jsonValue.actionControl.label] = valueToSet;
              return { type: "table", value: controlValue };
            }
          }
          let hasDatalineapprove: any = null;
          let actionTemplate: any = null;

          newControl.forEach((control: { layout: any[] }) => {
            control.layout.forEach((layout) => {
              if (layout.template.label === jsonValue.actionControl.label) {
                layout.data.value = valueToSet;
                const newLogic = checkCallLogic(allLogic, layout.template);
                hasDatalineapprove = newLogic.find(
                  (e) => e.logictype === "datalineapprove"
                );
                actionTemplate = layout;
              } else {
                try {
                  if (
                    layout.template.label === jsonValue.actionControl.isInTable
                  ) {
                    const colIdx = layout.template.attribute.column
                      ?.map((e: any) => e.label)
                      .indexOf(jsonValue.actionControl.label);

                    layout.data.row?.map((row: any) => {
                      row[colIdx].value = valueToSet;
                    });
                  }
                } catch (error) {
                  console.error(error);
                }
              }
            });
          });

          if (callGenAutoNumber(autoNumFormat, actionTemplate.template)) {
            newControl = await genAutoNum(
              newControl,
              autoNumFormat,
              memoDetail.template_id,
              memoDetail.status
            );
          }
          calculateAdvanceForm(newControl);
          if (hasDatalineapprove) {
            const res = await onProcessLogicLineApprove(
              hasDatalineapprove,
              actionTemplate.template,
              actionTemplate.data.value,
              newControl
            );
            if (res) {
              await getLineApproveForAmount(
                sumRes?._amount,
                res,
                LogicType.DataLineApprove,
                controlTemplate
              );
              setIsControlLoading(false);

              // return { type: "lineapprove", value: responseData };
            }
          }
          return { type: "control", value: newControl };
        }
        setIsControlLoading(false);
      }
    } catch (error) {
      console.error(
        "onProcessLogic=>error",
        error,
        "Logic=> " + logic,
        "value=> ",
        controlValue
      );
    }

    return { type: null, value: null };
  };

  const onDataSourceLoadRelated = async (dataJson: any) => {
    const dataSource = await fetch(
      "api/TemplateList/TemplateByid/LoadLogic/GetLoadDataFormControl",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataJson),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        return data;
        // setFormLoading(false);

        // onChangeValueForm(data, relateGroup);
      });
    return dataSource;
  };

  const onFindDataJson = (
    controlTemplate: any,
    controlValue: string,
    _control: any,
    jsonValue: ILogicTypeReladToLoadData,
    logicid: string,
    isInTable: boolean
  ) => {
    const response: {
      dataJson: any[];
      loadTo: { row: number; col: number };
      actionFrom: { row: number; col: number };
    } = {
      dataJson: [],
      loadTo: { row: -1, col: -1 },
      actionFrom: { row: -1, col: -1 },
    };

    if (!isInTable) {
      for (let j = 0; j < jsonValue.labelactions.length; j++) {
        const labelaction = jsonValue.labelactions[j].label;

        if (controlTemplate.label === labelaction) {
          const conditions = jsonValue.conditions;
          conditions.forEach((e) => {
            for (let k = 0; k < _control.length; k++) {
              const item = _control[k];
              for (let l = 0; l < item.layout.length; l++) {
                const _layout = item.layout[l];
                const _label = _layout.label || _layout.template.label;
                if (e.label === _label) {
                  response.dataJson.push({
                    Key: _label,
                    Value: _layout.data.value,
                    logicid: logicid,
                  });
                }
                if (jsonValue.autoloadvaluelabel.label === _label) {
                  response.loadTo = {
                    row: k,
                    col: l,
                  };
                }
              }
            }
          });
        }
      }
    } else {
      const _controlValue = controlValue as any;
      for (let j = 0; j < jsonValue.labelactions.length; j++) {
        const labelaction = jsonValue.labelactions[j];

        for (let k = 0; k < controlTemplate.attribute.column.length; k++) {
          const column = controlTemplate.attribute.column[k];

          if (column.control.template.label === labelaction.label) {
            const conditions = jsonValue.conditions;

            conditions.forEach((e) => {
              for (let k = 0; k < _control.length; k++) {
                const item = _control[k];
                for (let l = 0; l < item.layout.length; l++) {
                  const _layout = item.layout[l];
                  const _label = _layout.label || _layout.template.label;

                  if (e.label === _label) {
                    response.dataJson.push({
                      Key: _label,
                      Value: _layout.data.value,
                      logicid: logicid,
                    });
                  } else if (column.control.template.label === e.label) {
                    const exit = response.dataJson.find(
                      (e) => e.Key === column.control.template?.label
                    );
                    if (!exit) {
                      response.dataJson.push({
                        Key: column.control.template?.label,
                        Value:
                          _controlValue.values[column.control.template.label],
                        logicid: logicid,
                      });
                    }
                  }
                }
              }
            });

            response.actionFrom = { row: _controlValue.key, col: k };

            // }
          }
          if (
            column.control.template.label === jsonValue.autoloadvaluelabel.label
          ) {
            response.loadTo = {
              row: _controlValue.key,
              col: k,
            };
          }
        }
      }
    }

    return response;
  };
  const onDatareladToLoadData = async (dataJson: any) => {
    const response = await fetch(
      "api/TemplateList/TemplateByid/LoadLogic/GetLoadDataFormControl2",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataJson),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch(() => false);
    return response;
  };

  const onFilterCondotionsValue = (
    jsonValue: ILogicTypeDataLineApprove,
    _control: any,
    controlTemplate: any,
    controlValue: any
  ) => {
    let conditions: { label: string; value: string; action?: string }[] = [];
    for (let i = 0; i < jsonValue.Conditions.length; i++) {
      const condition = jsonValue.Conditions[i];

      if (_control) {
        for (let j = 0; j < _control.length; j++) {
          const item = _control[j];

          for (let k = 0; k < item.layout.length; k++) {
            const layout = item.layout[k];

            if (condition.label === layout.template.label) {
              if (layout.template.type === "dd") {
                conditions.push({
                  label: condition.label,
                  value: layout.data.value
                    ? layout.data.value.toLocaleString()
                    : "0",
                  action: condition.action,
                });
              } else if (layout.template.type === "cb") {
                conditions.push({
                  label: condition.label,
                  value: layout.data.value,
                  action: condition.action,
                });
              } else {
                conditions.push({
                  label: condition.label,
                  value: layout.data.value
                    ? layout.data.value.toLocaleString()
                    : "0",
                  action: condition.action,
                });
              }
            }
          }
        }

        // }
      }
    }
    return conditions;
  };

  return (
    <Spin
      className="loadingggggggggg"
      tip="Loading..."
      spinning={isControlLoading}
    >
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        initialValues={{ remember: true }}
        onFinish={handleSubmit(onSubmit)}
        className="form-core-control-container"
      >
        {fields.map((_control: any, idx: number) => {
          let dd: string = "set-padding-core-control";
          if (!_control.layout.every((e: any) => !e.isShow)) {
            return (
              <Row className={dd} key={_control.id + keyFrom}>
                  <Controls
                  key={
                    _control?.layout?.some(
                      (e: any) => e.template?.type === "tb"
                    )
                      ? _control.id
                      : keyFrom
                  }
                  calCulateCorecontrol={calCulateCorecontrol}
                  controlRef={controlRef}
                  nestIndex={idx}
                  onSubmit={handleSubmit(onSubmit)}
                  returnMessage={returnMessage}
                  listReturn={listReturn}
                  {...{
                    allLogicInTable,
                    tableOptions,
                    setTableOptions,
                    autoNumFormat,
                    buttonType,
                    canEditDoc,
                    control,
                    onCheckInterface,
                    register,
                    onControlChange: _onControlChange,

                    controlUpdate: update,
                    isControlLoading,
                    checkActionPage,
                    tableSummaries,
                    getValues,
                    updateTableSummaries,
                    memoDetail,
                  }}
                  documentNo={documentNo}
                  checkInterFace={checkInterFace}
                  interfaceButton={interfaceButton}
                  actionAlert={actionAlert}
                  setButtonType={setButtonType}
                  edFont={edFont}
                  defaultFont={defaultFont}
                />
              </Row>
            );
          }
        })}
      </Form>
    </Spin>
  );
};
