import moment from "moment";

export const sorterFunc = (
  a: any,
  b: any,
  key: string,
  type: "dec" | "asc" | null,
  filterType?: string,
  dateFormat?: string | null
) => {
  try {
    let nameA: any;
    let nameB: any;

    if (filterType === "date") {
      nameA = a[key] || a[key] !== "" ? new Date(dateFormat != null ? moment(a[key], dateFormat).format("DD MMM YYYY") : a[key]).getTime() : "";
      nameB = b[key] || b[key] !== "" ? new Date(dateFormat != null ? moment(b[key], dateFormat).format("DD MMM YYYY") : b[key]).getTime() : "";
      if (type === "asc") {
        return nameA - nameB;
      } else {
        return nameB - nameA;
      }
    } else {
      if (typeof a[key] !== "string") {
        return 0;
      }
      if (!isNaN(Number(a[key]?.replaceAll(",", "")))) 
        {
        nameA = Number(a[key]?.replaceAll(",", ""));
        nameB = Number(b[key]?.replaceAll(",", ""));

        if (type === "asc") {
          return nameA - nameB;
        } else {
          return nameB - nameA;
        }
      } else {
        nameA = a[key]?.toString().toLowerCase();
        nameB = b[key]?.toString().toLowerCase();
        if (type === "asc") {
          if (nameA > nameB) {
            return 1;
          } else if (nameB > nameA) {
            return -1;
          }
        } else {
          if (nameA > nameB) {
            return -1;
          } else if (nameB > nameA) {
            return 1;
          }
        }
        return 0;
      }
    }
  } catch (error) {
    console.error("helper=>sorterFunc=>error", error);
    return 0;
  }
};
