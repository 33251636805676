import React, { useEffect, useState } from "react";
import { Checkbox, Divider, Input } from "antd";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import type { CheckboxValueType } from "antd/es/checkbox/Group";
import { FilterDropdownProps, FilterValue } from "antd/lib/table/interface";
import { ColumnsProps } from "./DynamicTable";
const CheckboxGroup = Checkbox.Group;
type Props = {
  colProp: ColumnsProps;
  columnLabel: string;
  option: any[];
  filterDropDownProps: FilterDropdownProps;
  filterState?: Record<string, FilterValue | null>;
  dataSource: any[];
  allColumnProps: ColumnsProps[];
};

const SearchTextInTable = ({
  colProp,
  columnLabel,
  filterDropDownProps,
  filterState,
  dataSource,
  allColumnProps,
}: Props) => {
  const [checkedList, setCheckedList] = useState<CheckboxValueType[]>([]);
  const [checkAll, setCheckAll] = useState(true);
  const [searchValue, setSearchValue] = useState<string>("");
  const [columnOption, setColumnOption] = useState<any[]>([]);

  useEffect(() => {
    const data = filterDataSource();
    if (data) {
      setCheckedList([...data]);
    }
  }, []);

  useEffect(() => {
    if (checkedList.length === columnOption.length) {
      filterDropDownProps.setSelectedKeys([] as any);
    } else {
      filterDropDownProps.setSelectedKeys([...checkedList] as any);
    }
  }, [checkedList]);

  useEffect(() => {
    if (!filterDropDownProps.visible) return;
    setColumnOption(filterDataSource() ?? []);
  }, [filterDropDownProps.visible]);

  const filterDataSource = () => {
    if (filterState) {
      const filterKeys = Object.keys(filterState);
      const indexOfKey = filterKeys.indexOf(colProp.key);
      const columnPropsMap = new Map(
        allColumnProps.map((obj) => [obj.key, obj])
      );

      if (indexOfKey > 0) {
        const keysToConsider = filterKeys.slice(
          0,
          filterKeys.indexOf(colProp.key)
        );
        const result = dataSource.filter((item) => {
          return keysToConsider.every((key) => {
            if (columnPropsMap.has(key)) {
              return matchesFilter(
                item,
                filterState[key],
                columnPropsMap.get(key)
              );
            }
          });
        });
        return Array.from(new Set(result.map((e) => e[colProp.key])));
      } else if (indexOfKey === -1) {
        const filteredResult = dataSource.filter((item) =>
          filterKeys.every((key) => {
            const columnProps = columnPropsMap.get(key);
            return columnProps
              ? matchesFilter(item, filterState[key], columnProps)
              : true;
          })
        );

        // Use a Map to track unique values for better performance
        const uniqueValues = new Map();
        filteredResult.forEach((item) => {
          const value = item[colProp.key];
          if (!uniqueValues.has(value)) {
            uniqueValues.set(value, value);
          }
        });
        const result = Array.from(uniqueValues.values());

        return result;
      } else {
        return Array.from(new Set(dataSource?.map((e) => e[colProp.key]))).map(
          (e) => {
            return e?.toString();
          }
        );
      }
    }
  };

  const matchesFilter = (
    item: { [x: string]: any },
    filterValue: string | any[] | null,
    ColumnsProp?: ColumnsProps
  ) => {
    if (!ColumnsProp) return;
    const key = ColumnsProp.key;
    if (filterValue === null) {
      return true;
    }
    if (typeof item[key] === "boolean" && filterValue.length > 0) {
      const isActiveValue = filterValue[0] === "true";
      return item[key] === isActiveValue;
    }
    if (ColumnsProp?.type === "date") {
      if (!filterValue) return true;
      if (!filterValue[0]) return true;
      return item[key]
        ?.add(1, "seconds")
        .isBetween(filterValue[0][0], filterValue[0][1]);
    } else if (ColumnsProp?.type === "datetime") {
      if (!filterValue) return true;
      if (!filterValue[0]) return true;
      return item[key]?.isBetween(filterValue[0][0], filterValue[0][1]);
    } else if (ColumnsProp?.type === "number") {
      const recordVal = Number(item[key]);
      return recordVal >= filterValue[0][0] && recordVal <= filterValue[0][1];
    } else {
      return filterValue.includes(item[key]);
    }
  };

  const onChange = (list: CheckboxValueType[]) => {
    setCheckedList(list);
    setCheckAll(
      list.length ===
        columnOption.filter((e: string) =>
          e
            ?.toString()
            ?.toLowerCase()
            .includes(searchValue?.toString()?.toLowerCase())
        ).length
    );
  };

  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    setCheckedList(
      e.target.checked
        ? columnOption.filter((e: string) =>
            e
              ?.toString()
              ?.toLowerCase()
              .includes(searchValue?.toString()?.toLowerCase())
          )
        : []
    );
    setCheckAll(e.target.checked);
  };

  const onSearchCahnge = (value: string) => {
    setCheckedList(
      columnOption.filter((e: string) =>
        e?.toString()?.toLowerCase().includes(value?.toString()?.toLowerCase())
      )
    );
    setCheckAll(true);
    setSearchValue(value);
  };

  return (
    <>
      <div>
        <Input
          placeholder={`Search in ${columnLabel}`}
          onChange={(e) => {
            onSearchCahnge(e.target.value);
          }}
        />
      </div>
      <div>
        <Checkbox onChange={onCheckAllChange} checked={checkAll}>
          Select All
        </Checkbox>
        <Divider style={{ margin: 5 }} />
        <CheckboxGroup
          options={columnOption
            .filter((e: string) =>
              e
                ?.toString()
                ?.toLowerCase()
                .includes(searchValue?.toString()?.toLowerCase())
            )
            .map((e) => e?.toString())}
          value={checkedList}
          onChange={onChange}
          style={{
            paddingLeft: 20,
            display: "flex",
            flexDirection: "column",
            overflowY: "scroll",
            maxHeight: "20vh",
          }}
        />
      </div>
    </>
  );
};

export default SearchTextInTable;
