import React, { createContext, useState, useEffect } from "react";
import { GetAllMasterData } from "../Services/MasterDataService";

type IMasterSettingContextType = {
  masterSetting: { [key: string]: any };
  updateMasterData: (masterData: any[]) => void;
  getMasterSetting: () => Promise<{ [key: string]: any }>;
};

export const MasterSettingContext =
  createContext<IMasterSettingContextType | null>(null);

export const MasterSettingContextProvider: React.FC = (props) => {
  const [masterSetting, setMaterSetting] = useState<{ [key: string]: any }>({});

  useEffect(() => {
    fecthData();
  }, []);

  const fecthData = async () => {
    let settingDict = await getMasterSetting();
    setMaterSetting(settingDict);
  };
  const updateMasterData = (masterData: any[]) => {
    if (masterData.length > 0) {
      const _masterData = masterData.filter(
        (e) => ["FormatDate", "RefSpec" ,"splitPage"].includes(e.MasterType) && e.IsActive
      );
      let settingDict: any = {};
      _masterData.forEach((e: any) => {
        settingDict[e.MasterType] = e;
      });
      setMaterSetting(settingDict);
    }
  };

  const getMasterSetting = async () => {
    const masterData = await GetAllMasterData()
      .then((e) =>
        e.filter((e: any) => ["FormatDate", "RefSpec","splitPage"].includes(e.MasterType))
      )
      .then((e) => e.filter((e: any) => e.IsActive))
      .catch((err) => {
        console.error(err);
      });

    let settingDict: any = {};
    masterData?.forEach((e: any) => {
      settingDict[e.MasterType] = e;
    });
    return settingDict;
  };

  return (
    <MasterSettingContext.Provider
      value={{ masterSetting, updateMasterData, getMasterSetting }}
    >
      {props.children}
    </MasterSettingContext.Provider>
  );
};
