import React, { useState, useEffect, useMemo, Fragment } from "react";
import {
  AiOutlineReconciliation,
  AiOutlineSetting,
  AiOutlineUnorderedList,
  AiFillBell,
} from "react-icons/ai";
import { useTranslation } from "react-i18next";
import { RiTodoLine } from "react-icons/ri";
import { Link, useHistory, useLocation } from "react-router-dom";
// import Popover from "react-bootstrap/Popover";
import { Popover } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";

import "./ButtonNavigation.css";
import { log } from "console";
import { replaceSpecialChar } from "../../Helper/ReplaceSpecialChar";
import NotiPopOverContent from "../NotificationComponent/NotiPopOverContent";
import useMemoPage from "../../hooks/useMemoPage";
import { memoQueStatus } from "../../Context/MemoPageContext";
import useAlert from "../../hooks/useAlert";
import { v4 as uuidv4 } from "uuid";

interface Props {
  data: any[];
  setActive: any;
  setDefaultUrl: Boolean;
}

export const ButtonNavigation: React.FC<Props> = ({
  data,
  setActive,
  setDefaultUrl,
}) => {
  const [menuButton, setMenuButton] = useState<Object>({});
  const [sortableMenu, setSortableMenu] = useState<any[]>([]);
  const [toggleState, setToggleState] = useState<any[]>([]);
  const { t } = useTranslation(["translation"]);
  const history = useHistory();
  const location = useLocation();
  const { FailQue } = useMemoPage();
  const alert = useAlert();
  const [listNotify, setListNotity] = useState<memoQueStatus[]>(FailQue);
  const [activeIndex, setActiveIndex] = useState<number>(-1);
  const { search, pathname } = useLocation();
  const [uid, setUid] = useState<string | null>(null);
  const [workUrl, setWorkUrl] = useState<string | null | any>("");
  const [settingUrl, setSettingUrl] = useState<string | null | any>("");
  const [reportUrl, setReportUrl] = useState<string | null | any>("");

  useEffect(() => {
    const generatedUid = uuidv4();
    setUid(generatedUid);
  }, []);

  useEffect(() => {
    if (uid) {
      const replaceUrl = uid.replace(/-/g, "").toUpperCase();
      setWorkUrl(replaceUrl)
    }
  }, [uid, search, pathname, history, workUrl]);

  useEffect(() => {
    const generatedUid = uuidv4();
    setSettingUrl(generatedUid);
  }, []);

  useEffect(() => {
    if (uid) {
      const replaceUrl = uid.replace(/-/g, "").toUpperCase();
      setSettingUrl(replaceUrl)
    }
  }, [uid, search, pathname, history, workUrl]);
  useEffect(() => {
    const generatedUid = uuidv4();
    setReportUrl(generatedUid);
  }, []);

  useEffect(() => {
    if (uid) {
      const replaceUrl = uid.replace(/-/g, "").toUpperCase();
      setReportUrl(replaceUrl)
    }
  }, [uid, search, pathname, history, workUrl]);

  useMemo(() => {
    let result = data.reduce(function (r, a) {
      r[a.GroupMenu] = r[a.GroupMenu] || [];
      r[a.GroupMenu].push(a);
      return r;
    }, Object.create(null));

    const { Request, ...newResult } = result;

    let _res: any = {};
    for (const [key, value] of Object.entries(newResult)) {
      const uniqueArray = newResult[key].filter((value: any, index: any) => {
        const _value = JSON.stringify(value.SubMenu);
        return (
          index ===
          newResult[key].findIndex((obj: any) => {
            return JSON.stringify(obj.SubMenu) === _value;
          })
        );
      });
      _res[key] = uniqueArray;
    }

    let sortableMenu = [];
    for (var menu in _res) {
      sortableMenu.push([menu, _res[menu]]);
    }

    sortableMenu.sort(function (a, b) {
      return a[1][0].OrderGroup - b[1][0].OrderGroup;
    });

    setSortableMenu([...sortableMenu]);

    setMenuButton({ ..._res });
    const toggleArray: any = [];
    Object.entries(menuButton).forEach(([key, value]) => {
      toggleArray.push(false);
    });
    setToggleState([...toggleArray]);
  }, [data]);
  useEffect(() => {
    Object.entries(menuButton).forEach(([key, value], _idx) => {
      if (location.pathname.indexOf(key) !== -1) {
        let _toggleState = new Array(toggleState.length).fill(false);
        _toggleState[value[0].OrderGroup - 1] = true;
        setToggleState([..._toggleState]);
      }

      if (location.pathname !== `/${key}`) {
        value.forEach((_data: any) => {
          if (
            location.pathname === `/${_data.GroupMenu}` ||
            location.pathname.indexOf(_data.Url) !== -1
          ) {
            let _toggleState = new Array(toggleState.length).fill(false);

            _toggleState[value[0].OrderGroup - 1] = true;
            setToggleState([..._toggleState]);
          }
        });
      }
    });
  }, [location.pathname, menuButton]);
  useEffect(() => {
    const _toggleState = toggleState;
    if (location.pathname === "/Request") {
      setActive(true);
    }
    const res_every = _toggleState.every((b) => b === false);
    for (let i = 0; i < _toggleState.length; i++) {
      const state = _toggleState[i];
      if (state === true) {
        setActive(true);
      }
    }
    if (res_every && location.pathname !== "/Request") {
      setActive(false);
    }
  }, [toggleState]);
  const mapIcon = (key: string) => {
    if (key === "Worklist") {
      return <RiTodoLine />;
    }
    if (key === "Report") {
      return <AiOutlineReconciliation />;
    }
    if (key === "Settings") {
      return <AiOutlineSetting />;
    }
    if (key === "List and Documents") {
      return <AiOutlineUnorderedList />;
    }
  };

  useEffect(() => {
    if (FailQue.length > 0) {
      let newNoti = FailQue.filter(
        (i) => !listNotify.find((j) => i.jobId == j.jobId)
      );
      newNoti.forEach((i) => {
        alert.toggleAlert({
          type: "error",
          message: `Oops! Something went wrong`,
          description: `Something went wrong while sending your document ${i.documentNo} Please review and resend.\n
          ${i.message}`,
          duration: 0,
        });
      });
    }
    setListNotity(FailQue);
  }, [FailQue]);

  useEffect(() => {
    setNavbarActive();
  }, []);

  useEffect(() => {
    setNavbarActive();
  }, [setDefaultUrl]);

  const handleActiveIdx = (idx: number) => {
    setActiveIndex(idx);
  };
  const setNavbarActive = () => {
    const encodedPath = window.location.pathname;
    const decodedPath = decodeURIComponent(encodedPath).split("/false")[0];

    sortableMenu.forEach(([key, items], i) => {
      const matchedItem = items.find((item: any) => {
        if (window.location.search) {
          return (
            item.Url.toLowerCase().split("/").pop() ===
            window.location.search.toLowerCase().split("=").pop()
          );
        } else {
          const itemUrl = item.Url.toLowerCase().replace(/^\/|\/$/g, ""); // ลบ '/' ที่เริ่มต้นหรือท้าย
          const _decodedPath = decodedPath
            .toLowerCase()
            .replace(/^\/|\/$/g, ""); // ลบ '/' ที่เริ่มต้นหรือท้าย
          return itemUrl === _decodedPath;
        }
      });
      if (matchedItem) {
        setActiveIndex(i);
      }
    });
  };

  return (
    <div className="button-navbar-container">
      {sortableMenu.map((menu, _idx) => {
        // const menuUrls = menu[1];
        // const currentUrl = window.location.pathname.toLowerCase();
        // const isUrlMatch = menuUrls.some((urlObj: any) => {
        //   if (
        //     currentUrl.match(/\/dynamicreport\//) &&
        //     urlObj.GroupMenu === "List and Documents"
        //   ) {
        //     return true;
        //   } else if (
        //     currentUrl.match(/\/dynamicreport\//) &&
        //     urlObj.GroupMenu !== "List and Documents"
        //   ) {
        //     return false;
        //   }

        //   return (
        //     currentUrl.includes(urlObj.GroupMenu.toLowerCase()) ||
        //     currentUrl.includes(urlObj.Url.toLowerCase())
        //   );
        // });

        return (
          <div
            key={_idx}
            style={{ display: "flex", flexDirection: "column", gap: 10 }}
          >
            {menu[1].length > 0 &&
            menu[1].every(
              (_data: any) => _data.SubMenu !== null && _data.SubMenu !== ""
            ) ? (
              <Popover
                key={_idx}
                placement="bottom"
                content={() =>
                  menu[1]
                    .sort(
                      (a: any, b: any) =>
                        Number(a.OrderSub) - Number(b.OrderSub)
                    )
                    .map((e: any, idx: any) => {
                      const subMenu = replaceSpecialChar(e.SubMenu);
                      return (
                        <Fragment key={idx}>
                          {e.SubMenu !== "" &&
                          e.SubMenu !== null &&
                          e.GroupMenu !== "Settings" ? (
                            e.InternalUrl !== 0 ? (
                              <div key={idx}>
                                <Link
                                  to={e.Url + "?WFID=" + reportUrl}
                                  className="a-button-submenu"
                                  onClick={() => handleActiveIdx(_idx)}
                                >
                                  <p key={idx} className="button-submenu">
                                    {t(e.SubMenu)}
                                  </p>
                                </Link>
                              </div>
                            ) : (
                              <div key={idx}>
                                <a
                                  key={idx}
                                  className="button-submenu"
                                  onClick={() => {
                                    // history.push(e.Url);
                                    window.open(e.Url);
                                  }}
                                  target="_blank"
                                >
                                  {t(e.SubMenu)}
                                </a>
                              </div>
                            )
                          ) : e.InternalUrl !== 0 ? (
                            <div key={idx}>
                              <Link
                                className="a-button-submenu"
                                to={"/Settings?name=" + e.Url.replace("/", "") + "&WFID=" + settingUrl}
                                onClick={() => handleActiveIdx(_idx)}
                              >
                                <p key={idx} className="button-submenu">
                                  {t(e.SubMenu)}
                                </p>
                              </Link>
                            </div>
                          ) : (
                            <div key={idx}>
                              <p
                                key={idx}
                                className="button-submenu"
                                onClick={() => {
                                  const _url = e.Url;
                                  window.open(
                                    _url,
                                    "_blank",
                                    "noopener,noreferrer"
                                  );
                                }}
                              >
                                {t(e.SubMenu)}
                              </p>
                            </div>
                          )}
                        </Fragment>
                      );
                    })
                }
                trigger="hover"
              >
                <button
                  className={`button-nav ${activeIndex === _idx && `-active`}`}
                  id={replaceSpecialChar(menu[0] ?? "")}
                >
                  {mapIcon(menu[0])}
                  <p className="text-button">{t(menu[0])}</p>
                </button>
              </Popover>
            ) : menu[1]?.length < 2 &&
              `${menu[1][0]?.Url}` &&
              menu[1][0]?.InternalUrl === 0 ? (
              <a
                className={`button-nav ${activeIndex === _idx && `-active`}`}
                href={`${menu[1][0]?.Url}`}
                target={"_blank"}
                id={replaceSpecialChar(menu[0] ?? "")}
              >
                {mapIcon(menu[0])}
                <p className="text-button">{t(menu[0])}</p>
              </a>
            ) : (
              <Link
                className={`button-nav ${activeIndex === _idx && `-active`}`}
                to={menu[1].length < 2 ? `${menu[1][0].Url + "?WFID=" + workUrl}` : ""}
                onClick={() => handleActiveIdx(_idx)}
              >
                {mapIcon(menu[0])}
                <p className="text-button">{t(menu[0])}</p>
              </Link>
            )}
          </div>
        );
      })}
      <Popover
        key={"noti"}
        placement="bottom"
        content={() => <NotiPopOverContent />}
        trigger="click"
      >
        <button className={`button-nav`} id={"Notification"}>
          <AiFillBell />
          <p className="text-button">
            Notification{listNotify.length > 0 ? ` (${listNotify.length})` : ""}
          </p>
        </button>
      </Popover>
    </div>
  );
};
